.row-header{
    margin:0px auto;
    padding:0px auto;
}

.row-content {
    margin:0px auto;
    padding: 50px 0px 50px 0px;
    border-bottom: 1px ridge;
    min-height:400px;
}

.footer{
    background-color:  #FFFFFF;
    margin:15px;
    padding: 20px 0px 20px 0px;
}
.jumbotron {
    padding:70px 30px 70px 30px;
    margin:15px;
    background:  #FFFFFF;
    color:black;
}

address{
    font-size:80%;
    margin:0px;
    color:#0f0f0f;
}

.navbar-dark {
    background-color: #500000;
}
body{
    background-color: #FFF9CF;
    
}